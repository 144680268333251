import React from "react";

const Loader = () => {
    return (
        <div className="center-screen">
            <div className="loadingio-eclipse">
                <div className="ldio-rpinwye8j0b">
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader
